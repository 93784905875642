import * as React from 'react';
import { Drawer as DrawerPrimitive } from 'vaul';

import { cva, VariantProps } from 'class-variance-authority';
import { OmitStylesComponentProps } from '../../types';

const drawerOverlayVariants = cva(
    'rocco-drawer-overlay z-modalBackdrop fixed data-[state=open]:animate-in data-[state=closed]:animate-out data-[state=closed]:fade-out-0 data-[state=open]:fade-in-0',
    {
        variants: {
            variant: {
                default: 'inset-0 bg-black/80',
            },
        },
        defaultVariants: {
            variant: 'default',
        },
    },
);

export const DrawerOverlay = React.forwardRef<
    React.ElementRef<typeof DrawerPrimitive.Overlay>,
    OmitStylesComponentProps<typeof DrawerPrimitive.Overlay> &
        VariantProps<typeof drawerOverlayVariants>
>(({ variant, ...props }, ref) => (
    <DrawerPrimitive.Overlay
        ref={ref}
        className={drawerOverlayVariants({ variant })}
        {...props}
    />
));

DrawerOverlay.displayName = DrawerPrimitive.Overlay.displayName;
