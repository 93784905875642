import * as React from 'react';

import { cva, VariantProps } from 'class-variance-authority';
import { OmitStylesTextareaHTMLAttributes } from '../../types';

const textareaVariants = cva(
    'rocco-textarea flex min-h-[6rem] w-full rounded-2 px-3 py-2 text-[16px] md:text-base ring-offset-background focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:cursor-not-allowed disabled:opacity-50',
    {
        variants: {
            text: {
                default: '',
                medium: 'font-medium',
            },
            color: {
                default:
                    'border border-input bg-background placeholder:text-muted-foreground',
                lightOutline:
                    'border border-gray-200 bg-transparent text-grey-900 placeholder:text-gray-500/70',
                error: 'border-red-500',
                contrast:
                    'border border-white bg-white text-grey-900 placeholder:text-gray-900/50',
            },
        },
        defaultVariants: {
            color: 'default',
        },
    },
);

export interface TextareaProps
    extends OmitStylesTextareaHTMLAttributes<HTMLTextAreaElement>,
        VariantProps<typeof textareaVariants> {}

export const Textarea = React.forwardRef<HTMLTextAreaElement, TextareaProps>(
    ({ text, color, ...props }, ref) => {
        return (
            <textarea
                className={textareaVariants({ text, color })}
                ref={ref}
                {...props}
            />
        );
    },
);

Textarea.displayName = 'Textarea';
