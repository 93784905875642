import * as React from 'react';
import { useEffect, useRef } from 'react';
import { X } from 'lucide-react';
import { cva, VariantProps } from 'class-variance-authority';

import { cn } from '../../utils/cn';
import { OmitStylesInputHTMLAttributes } from '../../types';

const inputVariants = cva(
    'rocco-input flex w-full disabled:cursor-not-allowed disabled:opacity-50 [appearance:textfield] [&::-webkit-outer-spin-button]:appearance-none [&::-webkit-inner-spin-button]:appearance-none',
    {
        variants: {
            variant: {
                default: 'px-4 py-3',
                small: 'p-3',
                medium: 'px-6 py-3',
                wide: 'px-9 py-3',
                large: 'px-8 py-4',
            },
            text: {
                default: 'text-[16px] md:text-base leading-none',
                small: 'text-3.5 leading-none',
                medium: 'text-[16px] md:text-base leading-none font-medium file:font-medium placeholder:font-medium',
            },
            // why did we add these when we have text styles above?
            // TODO: refactor
            fontSize: {
                default: 'text-base',
                sm: 'text-sm',
            },
            color: {
                default:
                    'border border-gray-400 bg-black/5 text-grey-900 file:border-0 file:bg-transparent file:text-sm placeholder:text-gray-400',
                outline:
                    'border border-gray-400 bg-transparent text-grey-900 file:border-0 file:bg-transparent file:text-sm placeholder:text-gray-800/70',
                lightOutline:
                    'border border-gray-200 bg-transparent text-grey-900 file:border-0 file:bg-transparent file:text-sm placeholder:text-gray-500/70',
                blackOutline:
                    'border border-black bg-transparent text-grey-900 file:border-0 file:bg-transparent file:text-sm placeholder:text-gray-800/70',
                light: 'border border-transparent bg-secondary-surface text-grey-900 file:border-0 file:bg-transparent file:text-sm placeholder:text-gray-400',
                error: 'border border-red-500 bg-black/5 text-grey-900 file:border-0 file:bg-transparent file:text-sm placeholder:text-gray-400',
                contrast:
                    'border border-white bg-white text-grey-900 file:border-0 file:bg-transparent file:text-sm placeholder:text-gray-900/50',
                transparent:
                    'border border-transparent bg-transparent text-grey-900 file:border-0 file:bg-transparent file:text-sm placeholder:text-gray-400',
                gray: 'border border-transparent bg-black/5 text-gray-900 file:border-0 file:bg-transparent file:text-sm placeholder:text-gray-900/50',
            },
            shape: {
                default: 'rounded-2',
                rounded: 'rounded-full',
            },
            focus: {
                default:
                    'focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring',
                bordered:
                    'focus-visible:outline-none focus:border-gray-400 focus:border border-box',
            },
            prefixed: {
                true: '',
                false: '',
            },
            postfixed: {
                true: '',
                false: '',
            },
        },
        compoundVariants: [
            {
                variant: 'default',
                prefixed: true,
                className: 'pl-8',
            },
            {
                variant: 'wide',
                prefixed: true,
                className: 'pl-16',
            },
            {
                variant: 'default',
                postfixed: true,
                className: 'pr-9',
            },
            {
                variant: 'wide',
                postfixed: true,
                className: 'pr-18',
            },
        ],
        defaultVariants: {
            variant: 'default',
            text: 'medium',
            color: 'default',
            shape: 'default',
            focus: 'default',
        },
    },
);

const inputPrefixVariants = cva(
    'rocco-input-prefix absolute inset-y-0 left-0 flex items-center pointer-events-none',
    {
        variants: {
            variant: {
                default: 'pl-4',
                small: 'pl-3',
                medium: 'pl-6',
                wide: 'pl-8',
                large: 'pl-8',
            },
            text: {
                default: 'leading-4',
                small: 'leading-none',
                medium: 'font-medium',
            },
            color: {
                default: 'text-muted-foreground',
                outline: 'text-muted-foreground',
                lightOutline: 'text-muted-foreground',
                blackOutline: 'text-muted-foreground',
                error: 'text-muted-foreground',
                light: 'text-muted-foreground',
                contrast: 'text-muted-foreground',
                transparent: 'text-muted-foreground',
                gray: 'text-gray-900',
            },
        },

        defaultVariants: {
            variant: 'default',
            text: 'medium',
            color: 'default',
        },
    },
);

const inputPostfixVariants = cva(
    'rocco-input-prefix absolute inset-y-0 right-0 flex items-center pointer-events-none',
    {
        variants: {
            variant: {
                default: 'pr-4',
                small: 'pl-3',
                medium: 'pr-6',
                wide: 'pr-8',
                large: 'pr-8',
            },
            text: {
                default: 'leading-4',
                small: 'leading-none',
                medium: 'font-medium',
            },
            color: {
                default: 'text-muted-foreground',
                outline: 'text-muted-foreground',
                lightOutline: 'text-muted-foreground',
                blackOutline: 'text-muted-foreground',
                error: 'text-muted-foreground',
                light: 'text-muted-foreground',
                contrast: 'text-muted-foreground',
                transparent: 'text-muted-foreground',
                gray: 'text-gray-900',
            },
        },

        defaultVariants: {
            variant: 'default',
            text: 'medium',
            color: 'default',
        },
    },
);

const inputClearButtonVariants = cva(
    'rocco-input-clear z-1 absolute inset-y-0 right-0 flex cursor-pointer items-center disabled:cursor-not-allowed disabled:opacity-50',
    {
        variants: {
            variant: {
                default: 'pr-4',
                small: 'pr-3',
                medium: 'pr-6',
                wide: 'pr-8',
                large: 'pr-8',
            },
            color: {
                default: 'text-muted-foreground',
                outline: 'text-muted-foreground',
                lightOutline: 'text-muted-foreground',
                blackOutline: 'text-muted-foreground',
                error: 'text-muted-foreground',
                light: 'text-muted-foreground',
                contrast: 'text-muted-foreground',
                transparent: 'text-muted-foreground',
                gray: 'text-gray-900',
            },
        },

        defaultVariants: {
            variant: 'default',
            color: 'default',
        },
    },
);

export interface InputProps
    extends Omit<OmitStylesInputHTMLAttributes<HTMLInputElement>, 'prefix'>,
        VariantProps<typeof inputVariants> {
    className?: string;
    prefix?: string | React.ReactNode;
    postfix?: string | React.ReactNode;
    showClearButton?: boolean;
    onClear?: () => void;
}

export const Input = React.forwardRef<HTMLInputElement, InputProps>(
    (props, ref) => {
        const {
            className,
            type,
            prefix,
            postfix,
            showClearButton,
            onClear,
            variant,
            text,
            fontSize,
            color,
            shape,
            focus,
            value,
            disabled,
            onChange,
            ...rest
        } = props;

        const localRef = useRef<HTMLInputElement>(null);

        useEffect(() => {
            if (!ref) return;

            if (typeof ref === 'function') {
                ref(localRef.current);
            } else {
                ref.current = localRef.current;
            }
        }, [ref]);

        const handleClear = () => {
            if (localRef.current && onChange) {
                localRef.current.value = '';
                onChange({
                    target: localRef.current,
                } as React.ChangeEvent<HTMLInputElement>);

                onClear && onClear();
            }
        };

        return (
            <div className={cn('relative flex w-full items-center', className)}>
                {prefix && (
                    <span
                        className={inputPrefixVariants({
                            variant,
                            color,
                            text,
                        })}
                    >
                        {prefix}
                    </span>
                )}

                <input
                    type={type}
                    className={inputVariants({
                        variant,
                        color,
                        text,
                        fontSize,
                        shape,
                        focus,
                        prefixed: !!prefix,
                        postfixed: !!postfix || !!(showClearButton && value),
                    })}
                    ref={localRef}
                    value={value}
                    disabled={disabled}
                    onChange={onChange}
                    autoCapitalize={type === 'email' ? 'off' : undefined}
                    {...rest}
                />

                {showClearButton && value ? (
                    <button
                        className={inputClearButtonVariants({ variant, color })}
                        tabIndex={-1}
                        disabled={disabled}
                        aria-label="Clear"
                        // we need this onPointerDown magic to prevent the input from losing focus (sort of a race between click and blur events)
                        onPointerDown={e => e.preventDefault()}
                        onClick={handleClear}
                    >
                        <X className="size-4.5" />
                    </button>
                ) : (
                    postfix && (
                        <span
                            className={inputPostfixVariants({
                                variant,
                                color,
                                text,
                            })}
                        >
                            {postfix}
                        </span>
                    )
                )}
            </div>
        );
    },
);

Input.displayName = 'Input';
