import * as React from 'react';
import * as SwitchPrimitives from '@radix-ui/react-switch';

import { cva, VariantProps } from 'class-variance-authority';
import { OmitStylesComponentProps } from '../../types';
import { SwitchThumb } from './switch-thumb';

const switchVariants = cva('rocco-switch', {
    variants: {
        variant: {
            default:
                'peer inline-flex h-6 w-11 shrink-0 cursor-pointer items-center rounded-full border-2 border-transparent transition-colors focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 focus-visible:ring-offset-background disabled:cursor-not-allowed disabled:opacity-50 data-[state=checked]:bg-gray-900 data-[state=unchecked]:bg-gray-500',
        },
    },
    defaultVariants: {
        variant: 'default',
    },
});

export const Switch = React.forwardRef<
    React.ElementRef<typeof SwitchPrimitives.Root>,
    OmitStylesComponentProps<typeof SwitchPrimitives.Root> &
        VariantProps<typeof switchVariants>
>(({ variant, ...props }, ref) => (
    <SwitchPrimitives.Root
        className={switchVariants({ variant })}
        {...props}
        ref={ref}
    >
        <SwitchThumb />
    </SwitchPrimitives.Root>
));

Switch.displayName = SwitchPrimitives.Root.displayName;
