import { DisplayMode } from '@rocco/types/enum/display-mode';
import {
    SelectDesignBoardModal,
    uiStateAtomSelectDesignBoardModalIsOpened,
} from '@rocco/ui/design-board/components/SelectDesignBoardModal';
import { DesignBoardViewModel } from '@rocco/ui/design-board/view-models/design-board';
import { atom, useSetAtom } from 'jotai';
import { useCallback, useEffect, useRef, useState } from 'react';

///// State /////

export interface DesignBoardsTrackingConfig {
    /**
     * Callback fired when the design board selection modal is opened.
     * Used for tracking modal open events.
     */
    onOpen?: () => void;

    /**
     * Callback fired when a user selects a design board.
     * @param board - The design board being selected
     * Used for tracking board selection events.
     */
    onAfterBoardSelect?: (board: DesignBoardViewModel) => void;

    /**
     * Callback fired when a user clicks follow/save an item to a design board.
     * Used for tracking individual item follow events before the board selection modal opens.
     * @param itemId - The ID of the item being followed/saved (professional, product, project, article).
     */
    onBeforeFollow?: (itemId: number) => void;

    /**
     * Callback fired after items are successfully added to a design board.
     * Used for tracking when items are successfully saved to a board.
     * @param itemId - The ID of the item that was saved (professional, product, project, article).
     */
    onAfterFollow?: (itemId: number) => void;
}

/**
 * Context interface for managing design board actions and interactions.
 * Provides methods for showing the board selection modal and adding items to boards.
 * @interface DesignBoardsActionsContextType
 */
interface DesignBoardsActionsContextType {
    displayMode: DisplayMode;
    trackingConfig?: DesignBoardsTrackingConfig;

    /**
     * Shows the select design board modal and returns a promise that resolves with the selected board ID.
     * @param {boolean} allowCreateNewBoard - Whether to allow creation of a new board in the modal
     * @returns {Promise<number>} Promise that resolves with the selected board ID
     */
    showSelectDesignBoardModal: (
        allowCreateNewBoard: boolean,
    ) => Promise<DesignBoardViewModel>;

    /**
     * Adds specified items to a design board.
     * @param {number} boardId - ID of the target design board
     * @param {string} itemsType - Type of items being added (e.g., 'ProductPin', 'ArticlePin')
     * @param {number[]} items - Array of item IDs to add to the board
     * @returns {Promise<number>} Promise that resolves with the number of items successfully added
     */
    addItemsToBoard: (
        boardId: number,
        itemsType: string,
        items: number[],
    ) => Promise<number>;
}

/**
 * Atoms for managing design board actions context.
 * `localContextAtom` stores the core context data privately.
 * `uiContextAtomDesignBoardsActions` provides read-only access to the context data.
 */
export const uiContextAtomDesignBoardsActions = atom(get =>
    get(localContextAtom),
);
const localContextAtom = atom<DesignBoardsActionsContextType>();

///// Component /////

/**
 * Props interface for the DesignBoardsActions component.
 * @interface DesignBoardsActionsProps
 */
interface DesignBoardsActionsProps {
    /** Logged user flag */
    isUserLoggedIn: boolean;

    /** Display mode (mobile or desktop) */
    displayMode: DisplayMode;

    /** Indicates if any board-related operations are in progress */
    isLoading: boolean;

    /** Array of available design boards */
    boards?: DesignBoardViewModel[];

    /** Number of items that were successfully added in the last operation */
    addedItemsCount?: number;

    /** Data for the newly created board, if one was just created */
    createdBoardData?: DesignBoardViewModel;

    /** Tracking config */
    trackingConfig?: DesignBoardsTrackingConfig;

    /** Callback to fetch/refresh the list of boards */
    onFetchBoards: () => void;

    /**
     * Callback to create a new design board
     * @param {string} name - Name of the new board
     * @param {string} [description] - Optional description for the board
     * @param {boolean} [isPrivate] - Whether the board should be private
     */
    onCreateBoard: (
        name: string,
        description?: string,
        isPrivate?: boolean,
    ) => void;

    /**
     * Callback to add items to an existing board
     * @param {number} boardId - ID of the target board
     * @param {string} itemsType - Type of items being added
     * @param {number[]} items - Array of item IDs to add
     */
    onAddItemsToBoard: (
        boardId: number,
        itemsType: string,
        items: number[],
    ) => void;
}

/**
 * Component that manages design board actions and provides application-wide context for board-related operations.
 * Handles the display of the board selection modal and manages the addition of items to boards.
 * Provides a Jotai atom-based context that can be accessed anywhere in the application to interact with design boards.
 *
 * @component
 * @param {DesignBoardsActionsProps} props - Component props
 * @returns {JSX.Element} Rendered component
 */
export const DesignBoardsActions = (props: DesignBoardsActionsProps) => {
    const {
        isUserLoggedIn,
        displayMode,
        isLoading,
        boards,
        addedItemsCount,
        createdBoardData,
        onFetchBoards,
        onCreateBoard,
        onAddItemsToBoard,
        trackingConfig,
    } = props;

    const setSelectDesignBoardModalOpened = useSetAtom(
        uiStateAtomSelectDesignBoardModalIsOpened,
    );
    const setContext = useSetAtom(localContextAtom);

    const [allowCreateNewBoard, setAllowCreateNewBoard] = useState(false);
    const [localCreateNewBoard, setLocalCreateNewBoard] = useState<
        DesignBoardViewModel | undefined
    >(undefined);

    /**
     * Select design board modal
     */
    const selectDesignBoardPromiseRef = useRef<{
        resolve: (board: DesignBoardViewModel) => void;
        reject: () => void;
    } | null>(null);

    const showSelectDesignBoardModal = useCallback(
        (allowCreateNewBoard: boolean) => {
            trackingConfig?.onOpen?.();

            if (isUserLoggedIn) {
                setLocalCreateNewBoard(undefined);
                setSelectDesignBoardModalOpened(true);
                setAllowCreateNewBoard(allowCreateNewBoard);

                return new Promise<DesignBoardViewModel>((resolve, reject) => {
                    selectDesignBoardPromiseRef.current = { resolve, reject };
                });
            } else {
                return Promise.resolve({
                    id: 0,
                    title: 'My design board',
                    isPrivate: true,
                    urlSegment: '',
                    isNew: false,
                    pins: [],
                });
            }
        },
        [isUserLoggedIn, setSelectDesignBoardModalOpened, trackingConfig],
    );

    /**
     * Add items to board
     */
    const addItemsToBoardPromiseRef = useRef<{
        resolve: (count: number) => void;
        reject: () => void;
    } | null>(null);

    const addItemsToBoard = useCallback(
        (boardId: number, itemsType: string, items: number[]) => {
            onAddItemsToBoard(boardId, itemsType, items);

            return new Promise<number>((resolve, reject) => {
                addItemsToBoardPromiseRef.current = { resolve, reject };
            });
        },
        [onAddItemsToBoard],
    );

    /**
     * Set the local create new board data
     * We need a local state to store the created board data even though we receive it as a prop
     * because the modal needs to maintain its own internal state. When the modal is closed and reopened,
     * we want to reset this state, but we don't want to modify the original prop value.
     */
    useEffect(() => {
        setLocalCreateNewBoard(createdBoardData);
    }, [createdBoardData]);

    /**
     * Resolves or rejects the promise when items are added to board
     * If items were successfully added (addedItemsCount exists), resolve with count
     * If loading is complete but no items added, reject
     */
    useEffect(() => {
        if (addedItemsCount) {
            addItemsToBoardPromiseRef.current?.resolve(addedItemsCount);
        } else if (!isLoading) {
            addItemsToBoardPromiseRef.current?.reject();
        }
    }, [addedItemsCount, isLoading]);

    /**
     * Provide context
     */
    useEffect(() => {
        const providerValue = {
            displayMode,
            showSelectDesignBoardModal,
            addItemsToBoard,
            trackingConfig,
        };

        setContext(providerValue); // jotai atom setter
    }, [
        setContext,
        displayMode,
        showSelectDesignBoardModal,
        addItemsToBoard,
        trackingConfig,
    ]);

    return (
        <div>
            <SelectDesignBoardModal
                displayMode={displayMode}
                allowCreateNewBoard={allowCreateNewBoard}
                allowClickAndSelect={displayMode === DisplayMode.Mobile}
                boards={boards}
                createdBoardData={localCreateNewBoard}
                onFetchBoards={onFetchBoards}
                onCreateBoard={onCreateBoard}
                onBoardSelected={(board: DesignBoardViewModel) => {
                    selectDesignBoardPromiseRef.current?.resolve(board);
                }}
                onCancel={() => {
                    selectDesignBoardPromiseRef.current?.reject();
                }}
            />
        </div>
    );
};
