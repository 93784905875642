import * as React from 'react';
import { Slot } from '@radix-ui/react-slot';

import { useFormField } from './use-form-field';
import { OmitStylesComponentProps } from '../../types';

export const FormControl = React.forwardRef<
    React.ElementRef<typeof Slot>,
    OmitStylesComponentProps<typeof Slot>
>(({ ...props }, ref) => {
    const { error, formItemId, formDescriptionId, formMessageId } =
        useFormField();

    return (
        <Slot
            ref={ref}
            id={formItemId}
            aria-describedby={
                !error
                    ? `${formDescriptionId}`
                    : `${formDescriptionId} ${formMessageId}`
            }
            aria-invalid={!!error}
            {...props}
        />
    );
});

FormControl.displayName = 'FormControl';
