import { z } from 'zod';
import { zodResolver } from '@hookform/resolvers/zod';
import { atom, useAtom } from 'jotai';
import { useForm } from 'react-hook-form';
import { ReactComponent as PlusIcon } from '@rocco/icons/svg/plus-no-padding.svg';
import { ReactComponent as ArrowRightIcon } from '@rocco/icons/svg/arrow/arrow-right.svg';
import {
    Form,
    FormControl,
    FormField,
    FormItem,
    FormLabel,
    FormMessage,
} from '@rocco/components/form';
import { Image } from '@rocco/components/image';
import { DesignBoardViewModel } from '@rocco/ui/design-board/view-models/design-board';
import React, {
    useCallback,
    useEffect,
    useImperativeHandle,
    useState,
} from 'react';
import { Switch } from '@rocco/components/switch';
import { Textarea } from '@rocco/components/textarea';
import { Input } from '@rocco/components/input';
import { Button } from '@rocco/components/button';
import { cn } from '@rocco/utils/cn';
import { Label } from '@rocco/components/label';
import { Skeleton } from '@rocco/components/skeleton';
import { CheckIcon } from 'lucide-react';
import {
    Drawer,
    DrawerContent,
    DrawerFooter,
    DrawerHeader,
    DrawerTitle,
} from '@rocco/components/drawer';
import { DisplayMode } from '@rocco/types/enum/display-mode';

export const uiStateAtomSelectDesignBoardModalIsOpened = atom(false);

interface SelectDesignBoardModalProps {
    displayMode: DisplayMode;
    allowCreateNewBoard: boolean;
    allowClickAndSelect: boolean;
    boards?: DesignBoardViewModel[];
    createdBoardData?: DesignBoardViewModel;
    onFetchBoards: () => void;
    onCreateBoard: (
        name: string,
        description?: string,
        isPrivate?: boolean,
    ) => void;
    onBoardSelected: (board: DesignBoardViewModel) => void;
    onCancel: () => void;
}

export const SelectDesignBoardModal = (props: SelectDesignBoardModalProps) => {
    const {
        displayMode,
        allowCreateNewBoard,
        allowClickAndSelect,
        boards,
        createdBoardData,
        onFetchBoards,
        onCreateBoard,
        onBoardSelected,
        onCancel,
    } = props;

    const [
        uiStateSelectDesignBoardModalIsOpened,
        setUiStateSelectDesignBoardModalIsOpened,
    ] = useAtom(uiStateAtomSelectDesignBoardModalIsOpened);

    const isMobile = displayMode === DisplayMode.Mobile;

    const [isAddingNewBoard, setIsAddingNewBoard] = useState(false);
    const [selectedBoard, setSelectedBoard] =
        useState<DesignBoardViewModel | null>(null); // used for click and select mode
    const formRef = React.useRef<{ submit: () => void }>(null);

    const handleBoardSelect = useCallback(
        (board: DesignBoardViewModel) => {
            onBoardSelected(board);
            setUiStateSelectDesignBoardModalIsOpened(false);
        },
        [setUiStateSelectDesignBoardModalIsOpened, onBoardSelected],
    );

    const handleBoardCreate = useCallback(
        (title: string, description: string, isPrivate: boolean) => {
            onCreateBoard(title, description, isPrivate);
        },
        [onCreateBoard],
    );

    /**
     * Handles auto-selection of newly created design board
     *
     * - Only triggers when createdBoardData exists and has valid ID > 0
     * - If createdBoardData is null/undefined, no action taken
     * - If createdBoardData.id <= 0, invalid ID, no action taken
     */
    useEffect(() => {
        if (createdBoardData && createdBoardData.id > 0) {
            try {
                onBoardSelected(createdBoardData);
            } finally {
                setUiStateSelectDesignBoardModalIsOpened(false);
            }
        }
    }, [
        createdBoardData,
        onBoardSelected,
        setUiStateSelectDesignBoardModalIsOpened,
    ]);

    /**
     * Handles modal open state changes
     *
     * - Only triggers when modal is opened (true)
     * - Resets "adding new board" state if active (assuming it's a just opened modal and we don't want to show the new board form)
     * - Resets "selected board" state if active (in case of click and select mode)
     * - No action taken when modal is closed (false)
     * - Prevents unnecessary board fetches when modal is already open
     */
    useEffect(() => {
        if (uiStateSelectDesignBoardModalIsOpened) {
            isAddingNewBoard && setIsAddingNewBoard(false);
            allowClickAndSelect && selectedBoard && setSelectedBoard(null);
            onFetchBoards();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [uiStateSelectDesignBoardModalIsOpened]);

    return (
        <Drawer
            handleOnly={!isMobile}
            open={uiStateSelectDesignBoardModalIsOpened}
            onOpenChange={setUiStateSelectDesignBoardModalIsOpened}
        >
            <DrawerContent
                noHandle={!isMobile}
                hideDefaultCloseButton={isMobile}
                limitMaxHeight={isMobile}
            >
                <div className="flex h-full w-full flex-col">
                    <DrawerHeader>
                        <DrawerTitle>
                            {isAddingNewBoard
                                ? 'New Design Board'
                                : 'Select Design Board'}
                        </DrawerTitle>
                    </DrawerHeader>

                    <div
                        className={cn(
                            'scrollbar-hidden w-dvw shrink overflow-y-scroll md:w-[56.125rem] md:px-6',
                            isAddingNewBoard
                                ? 'h-[29.78rem] md:h-[21.75rem]'
                                : 'h-[34.25rem] md:h-[26.5rem]',
                        )}
                    >
                        {isAddingNewBoard ? (
                            <AddNewBoard
                                onCreateNewBoard={handleBoardCreate}
                                formRef={formRef}
                            />
                        ) : (
                            <SelectDesignBoard
                                boards={boards}
                                allowClickAndSelect={allowClickAndSelect}
                                selectedBoard={selectedBoard}
                                setSelectedBoard={setSelectedBoard}
                                allowCreateNewBoard={allowCreateNewBoard}
                                onCreateNewBoard={() =>
                                    setIsAddingNewBoard(true)
                                }
                                onBoardSelected={handleBoardSelect}
                            />
                        )}
                    </div>

                    {isAddingNewBoard ? (
                        <DrawerFooter>
                            <Button
                                size="xLarge"
                                color={
                                    displayMode === DisplayMode.Mobile
                                        ? 'outline'
                                        : 'secondary'
                                }
                                minWidth="200"
                                onClick={() =>
                                    isAddingNewBoard
                                        ? setIsAddingNewBoard(false)
                                        : onCancel()
                                }
                            >
                                <span className="text-lg">Cancel</span>
                            </Button>
                            <Button
                                size="xLarge"
                                minWidth="200"
                                onClick={() => formRef.current?.submit()}
                            >
                                <span className="text-lg">Save</span>
                            </Button>
                        </DrawerFooter>
                    ) : (
                        allowClickAndSelect && (
                            <DrawerFooter>
                                <Button
                                    size="xLarge"
                                    minWidth="200"
                                    disabled={!selectedBoard}
                                    onClick={() =>
                                        selectedBoard &&
                                        handleBoardSelect(selectedBoard)
                                    }
                                >
                                    <span className="flex items-center justify-between gap-2 text-lg">
                                        <span>Save</span>
                                        <ArrowRightIcon className="size-4.5" />
                                    </span>
                                </Button>
                            </DrawerFooter>
                        )
                    )}
                </div>
            </DrawerContent>
        </Drawer>
    );
};

///// Content (Select Design Board) /////

interface SelectDesignBoardProps {
    allowClickAndSelect: boolean;
    selectedBoard: DesignBoardViewModel | null;
    setSelectedBoard: (board: DesignBoardViewModel | null) => void;
    boards?: DesignBoardViewModel[];
    allowCreateNewBoard: boolean;
    onCreateNewBoard: () => void;
    onBoardSelected: (board: DesignBoardViewModel) => void;
}

const SelectDesignBoard = (props: SelectDesignBoardProps) => {
    const {
        boards,
        allowCreateNewBoard,
        onCreateNewBoard,
        onBoardSelected,
        allowClickAndSelect,
        selectedBoard,
        setSelectedBoard,
    } = props;

    return (
        <div className="select-none py-[1.1875rem] md:py-8 px-2 md:px-0 grid grid-cols-2 md:grid-cols-3 gap-x-2 gap-y-6 md:gap-y-12">
            {allowCreateNewBoard && (
                <SelectDesignBoardModalNewBoardButton
                    onCreateNewBoard={onCreateNewBoard}
                />
            )}

            {boards
                ? boards.map(board => (
                      <SelectDesignBoardModalItem
                          key={board.id}
                          designBoard={board}
                          isSelected={selectedBoard?.id === board.id}
                          allowClickAndSelect={allowClickAndSelect}
                          onBoardSelected={
                              allowClickAndSelect
                                  ? setSelectedBoard
                                  : onBoardSelected
                          }
                      />
                  ))
                : [0, 1, 2].map(pin => (
                      <SelectDesignBoardModalItemSkeleton key={pin} />
                  ))}
        </div>
    );
};

///// Content (Add New Board) /////

interface AddNewBoardProps {
    onCreateNewBoard: (
        title: string,
        description: string,
        isPrivate: boolean,
    ) => void;
    formRef?: React.RefObject<{ submit: () => void }>;
}

const AddNewBoard = (props: AddNewBoardProps) => {
    const { onCreateNewBoard } = props;

    const formSchema = z.object({
        title: z.string().min(1, 'Title is required'),
        description: z.string().optional(),
        isPrivate: z.boolean(),
    });

    type FormValues = z.infer<typeof formSchema>;

    const form = useForm<FormValues>({
        resolver: zodResolver(formSchema),
        defaultValues: {
            title: '',
            description: '',
            isPrivate: false,
        },
    });

    const onSubmit = (values: FormValues) => {
        onCreateNewBoard(
            values.title,
            values.description ?? '',
            !!values.isPrivate,
        );
    };

    useImperativeHandle(props.formRef, () => ({
        submit: () => form.handleSubmit(onSubmit)(),
    }));

    return (
        <div className="w-full py-8 px-6 md:px-0">
            <Form {...form}>
                <form
                    onSubmit={() => form.handleSubmit(onSubmit)}
                    className="space-y-8"
                >
                    <FormField
                        control={form.control}
                        name="title"
                        render={({ field }) => (
                            <FormItem>
                                <FormLabel>
                                    <span className="text-base font-medium leading-tight">
                                        * Design Board title
                                    </span>
                                </FormLabel>
                                <FormControl>
                                    <Input
                                        variant="small"
                                        color="contrast"
                                        text="default"
                                        type="text"
                                        placeholder="My First Design Board"
                                        {...field}
                                    />
                                </FormControl>
                                <FormMessage />
                            </FormItem>
                        )}
                    />

                    <FormField
                        control={form.control}
                        name="description"
                        render={({ field }) => (
                            <FormItem>
                                <FormLabel>
                                    <span className="text-base font-medium leading-tight">
                                        Description
                                    </span>
                                </FormLabel>
                                <FormControl>
                                    <Textarea
                                        color="contrast"
                                        placeholder="Enter Description"
                                        {...field}
                                    />
                                </FormControl>
                                <FormMessage />
                            </FormItem>
                        )}
                    />

                    <FormField
                        control={form.control}
                        name="isPrivate"
                        render={({ field }) => (
                            <FormItem>
                                <FormControl>
                                    <div className="flex gap-2 items-center -mt-2">
                                        <Switch
                                            id="private-board"
                                            checked={field.value}
                                            onCheckedChange={field.onChange}
                                        />
                                        <Label
                                            htmlFor="private-board"
                                            text="medium"
                                        >
                                            Private Board
                                        </Label>
                                    </div>
                                </FormControl>
                            </FormItem>
                        )}
                    />
                </form>
            </Form>
        </div>
    );
};

///// New Board Button /////

interface SelectDesignBoardModalNewBoardButtonProps {
    onCreateNewBoard: () => void;
}

const SelectDesignBoardModalNewBoardButton = (
    props: SelectDesignBoardModalNewBoardButtonProps,
) => {
    const { onCreateNewBoard } = props;

    return (
        <button
            onClick={onCreateNewBoard}
            className="w-full aspect-[4/2.9] md:aspect-[4/3] border border-dashed border-gray-900 rounded-lg flex flex-col items-center justify-start hover:border-gray-900/90 transition-colors"
        >
            <div className="mt-10 md:mt-14 size-6 flex items-center justify-center text-gray-500">
                <PlusIcon />
            </div>
            <span className="mt-8.5 text-sm text-gray-900">New Board</span>
        </button>
    );
};

///// Item /////

interface SelectDesignBoardModalItemProps {
    designBoard: DesignBoardViewModel;
    isSelected: boolean;
    allowClickAndSelect: boolean;
    onBoardSelected: (board: DesignBoardViewModel) => void;
}

const SelectDesignBoardModalItem = (props: SelectDesignBoardModalItemProps) => {
    const { designBoard, isSelected, allowClickAndSelect, onBoardSelected } =
        props;

    return (
        <div
            className="relative pt-[0.64231rem] pr-[0.77906rem] pb-[1.2rem] pl-[0.595rem] md:pt-2 w-full group grid grid-cols-1 grid-rows-3 aspect-[4/2.9] md:aspect-[4/3] bg-white rounded-lg overflow-hidden cursor-pointer"
            onClick={() => onBoardSelected(designBoard)}
        >
            <div className="h-full row-span-2 grid grid-cols-3 grid-rows-2 gap-1">
                {[0, 1, 2].map(index => {
                    const pin = designBoard.pins?.[index];

                    return pin ? (
                        <div
                            key={pin.id}
                            className={cn(
                                'rounded-sm bg-gray-200 relative h-full overflow-hidden',
                                index === 0
                                    ? 'col-span-2 row-span-2'
                                    : undefined,
                            )}
                        >
                            <Image
                                src={pin.image}
                                alt=""
                                fill={true}
                                fit="cover"
                                multiplied={true}
                            />
                        </div>
                    ) : (
                        <div
                            key={index}
                            className={cn(
                                'rounded-sm bg-gray-100 relative h-full',
                                index === 0
                                    ? 'col-span-2 row-span-2'
                                    : undefined,
                            )}
                        />
                    );
                })}
            </div>

            <div className="h-full">
                <span className="w-full mt-2 text-gray-900 font-medium text-left line-clamp-2 text-ellipsis">
                    {designBoard.title}
                </span>
            </div>

            {!allowClickAndSelect && (
                <div className="absolute inset-0 hidden group-hover:flex-centered bg-gray-500/20">
                    <div className="text-base font-medium inline-flex items-center justify-center whitespace-nowrap border bg-brick-50 text-primary-foreground border-brick-50 px-6 py-2 rounded-full">
                        Save
                    </div>
                </div>
            )}

            {allowClickAndSelect && isSelected && (
                <div className="absolute inset-0 flex-centered">
                    <div className="size-14.5 rounded-full bg-brick-800 flex-centered">
                        <CheckIcon className="size-5.5 text-gray-900" />
                    </div>
                </div>
            )}
        </div>
    );
};

///// Item Skeleton /////

const SelectDesignBoardModalItemSkeleton = () => (
    <div className="p-2 w-full grid grid-cols-1 grid-rows-3 aspect-[4/3] bg-white rounded-lg overflow-hidden">
        <div className="h-full row-span-2 grid grid-cols-3 grid-rows-2 gap-1">
            {[0, 1, 2].map(pin => (
                <Skeleton
                    key={pin}
                    className={cn(
                        'h-full',
                        pin === 0 ? 'col-span-2 row-span-2' : undefined,
                    )}
                />
            ))}
        </div>

        <div className="h-full">
            <Skeleton className="h-4 w-full mt-2" />
        </div>
    </div>
);
