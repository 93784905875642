import * as React from 'react';
import { Drawer as DrawerPrimitive } from 'vaul';
import { OmitStylesComponentProps } from '../../types';

export const Drawer = ({
    shouldScaleBackground = true,
    ...props
}: OmitStylesComponentProps<typeof DrawerPrimitive.Root>) => (
    <DrawerPrimitive.Root
        shouldScaleBackground={shouldScaleBackground}
        {...props}
    />
);

Drawer.displayName = 'Drawer';
