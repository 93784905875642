import * as React from 'react';
import * as SwitchPrimitives from '@radix-ui/react-switch';

import { cva, VariantProps } from 'class-variance-authority';
import { OmitStylesComponentProps } from '../../types';

const switchThumbVariants = cva('rocco-switch-thumb', {
    variants: {
        variant: {
            default:
                'pointer-events-none block h-5 w-5 rounded-full bg-background shadow-lg ring-0 transition-transform data-[state=checked]:translate-x-5 data-[state=unchecked]:translate-x-0',
        },
    },
    defaultVariants: {
        variant: 'default',
    },
});

export const SwitchThumb = React.forwardRef<
    React.ElementRef<typeof SwitchPrimitives.Thumb>,
    OmitStylesComponentProps<typeof SwitchPrimitives.Thumb> &
        VariantProps<typeof switchThumbVariants>
>(({ variant, ...props }, ref) => (
    <SwitchPrimitives.Thumb
        ref={ref}
        className={switchThumbVariants({ variant })}
        {...props}
    />
));

SwitchThumb.displayName = SwitchPrimitives.Thumb.displayName;
