import * as React from 'react';
import { FieldPath, FieldValues, FormProvider } from 'react-hook-form';

export const Form = FormProvider;

interface FormFieldContextValue<
    TFieldValues extends FieldValues = FieldValues,
    TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>,
> {
    name: TName;
}

export const FormFieldContext = React.createContext<FormFieldContextValue>(
    {} as FormFieldContextValue,
);

interface FormItemContextValue {
    id: string;
}

export const FormItemContext = React.createContext<FormItemContextValue>(
    {} as FormItemContextValue,
);
