import * as React from 'react';
import * as LabelPrimitive from '@radix-ui/react-label';

import { cva, type VariantProps } from 'class-variance-authority';
import { OmitStylesComponentProps } from '../../types';

const labelVariants = cva(
    'rocco-label peer-disabled:cursor-not-allowed peer-disabled:opacity-70',
    {
        variants: {
            variant: {
                default: 'text-sm leading-none',
                error: 'text-destructive',
            },
            text: {
                default: '',
                medium: 'font-medium',
            },
            target: {
                default: 'cursor-unset',
                checkbox:
                    'ml-0 md:ml-2 my-1.5 leading-5 cursor-pointer relative',
                checkboxReversed:
                    'ml-0 my-1.5 leading-5 cursor-pointer relative top-[2px]',
            },
            singleLine: {
                true: 'line-clamp-1',
                false: '',
            },
        },
        defaultVariants: {
            variant: 'default',
            text: 'default',
            target: 'default',
            singleLine: false,
        },
    },
);

export const Label = React.forwardRef<
    React.ElementRef<typeof LabelPrimitive.Root>,
    OmitStylesComponentProps<typeof LabelPrimitive.Root> &
        VariantProps<typeof labelVariants>
>(({ variant, text, target, singleLine, ...props }, ref) => (
    <LabelPrimitive.Root
        ref={ref}
        className={labelVariants({ variant, text, target, singleLine })}
        {...props}
    />
));

Label.displayName = LabelPrimitive.Root.displayName;
