import * as React from 'react';
import * as LabelPrimitive from '@radix-ui/react-label';
import { cva, VariantProps } from 'class-variance-authority';

import { cn } from '../../utils/cn';
import { useFormField } from './use-form-field';

const formLabelVariants = cva(
    'rocco-form-label text-sm font-medium leading-none peer-disabled:cursor-not-allowed peer-disabled:opacity-70',
    {
        variants: {
            variant: {
                default: '',
                error: 'text-destructive',
                errorDarker: 'text-[#BE6F4F]',
            },
        },
        defaultVariants: {
            variant: 'default',
        },
    },
);

export const FormLabel = React.forwardRef<
    React.ElementRef<typeof LabelPrimitive.Root>,
    React.ComponentPropsWithoutRef<typeof LabelPrimitive.Root> &
        VariantProps<typeof formLabelVariants> & {
            errorVariant?: VariantProps<typeof formLabelVariants>['variant'];
        }
>(({ className, variant, errorVariant, ...props }, ref) => {
    const { error, formItemId } = useFormField();

    return (
        <LabelPrimitive.Root
            ref={ref}
            htmlFor={formItemId}
            className={cn(
                formLabelVariants({
                    variant: error ? (errorVariant ?? 'error') : variant,
                    className,
                }),
            )}
            {...props}
        />
    );
});

FormLabel.displayName = 'FormLabel';
