import * as React from 'react';

import { cva, VariantProps } from 'class-variance-authority';
import { OmitStylesHTMLAttributes } from '../../types';

const drawerFooterVariants = cva(
    'rocco-drawer-footer flex flex-col-reverse gap-2 sm:flex-row sm:justify-end',
    {
        variants: {
            variant: {
                default:
                    'px-6 py-5.5 md:pb-5 md:pt-4 border-t border-gray-900/30',
            },
        },
        defaultVariants: {
            variant: 'default',
        },
    },
);

export const DrawerFooter = ({
    variant,
    ...props
}: OmitStylesHTMLAttributes<HTMLDivElement> &
    VariantProps<typeof drawerFooterVariants>) => (
    <div className={drawerFooterVariants({ variant })} {...props} />
);

DrawerFooter.displayName = 'DrawerFooter';
