import * as React from 'react';

import { useFormField } from './use-form-field';
import { OmitStylesHTMLAttributes } from '../../types';
import { cva, VariantProps } from 'class-variance-authority';

const formDescriptionVariants = cva('rocco-form-description', {
    variants: {
        variant: {
            default: 'text-sm text-muted-foreground',
        },
    },
    defaultVariants: {
        variant: 'default',
    },
});

export const FormDescription = React.forwardRef<
    HTMLParagraphElement,
    OmitStylesHTMLAttributes<HTMLParagraphElement> &
        VariantProps<typeof formDescriptionVariants>
>(({ variant, ...props }, ref) => {
    const { formDescriptionId } = useFormField();

    return (
        <p
            ref={ref}
            id={formDescriptionId}
            className={formDescriptionVariants({ variant })}
            {...props}
        />
    );
});

FormDescription.displayName = 'FormDescription';
