import * as React from 'react';
import { Drawer as DrawerPrimitive } from 'vaul';

import { cva, VariantProps } from 'class-variance-authority';

const drawerTitleVariants = cva('rocco-drawer-title', {
    variants: {
        variant: {
            default: 'text-xl text-foreground text-center md:text-left',
        },
    },
    defaultVariants: {
        variant: 'default',
    },
});

export const DrawerTitle = React.forwardRef<
    React.ElementRef<typeof DrawerPrimitive.Title>,
    React.ComponentPropsWithoutRef<typeof DrawerPrimitive.Title> &
        VariantProps<typeof drawerTitleVariants>
>(({ variant, ...props }, ref) => (
    <DrawerPrimitive.Title
        ref={ref}
        className={drawerTitleVariants({ variant })}
        {...props}
    />
));

DrawerTitle.displayName = DrawerPrimitive.Title.displayName;
