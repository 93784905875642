import * as React from 'react';
import { cva, VariantProps } from 'class-variance-authority';
import { CircleAlert } from 'lucide-react';

import { cn } from '../../utils/cn';
import { useFormField } from './use-form-field';

const formMessageVariants = cva('rocco-form-message mt-2 text-sm', {
    variants: {
        variant: {
            default: 'text-destructive',
            darker: 'text-[#BE6F4F]',
        },
    },
    defaultVariants: {
        variant: 'default',
    },
});

export const FormMessage = React.forwardRef<
    HTMLParagraphElement,
    React.HTMLAttributes<HTMLParagraphElement> &
        VariantProps<typeof formMessageVariants>
>(({ className, children, variant, ...props }, ref) => {
    const { error, formMessageId } = useFormField();
    const body = error ? String(error?.message ?? '') : children;

    if (!body) {
        return null;
    }

    return (
        <p
            ref={ref}
            id={formMessageId}
            className={cn(formMessageVariants({ variant, className }))}
            {...props}
        >
            <span className="flex items-center gap-1">
                <CircleAlert className="size-3" />
                <span>{body}</span>
            </span>
        </p>
    );
});

FormMessage.displayName = 'FormMessage';
