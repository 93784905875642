import * as React from 'react';

import { cva, VariantProps } from 'class-variance-authority';
import { OmitStylesHTMLAttributes } from '../../types';

const drawerHeaderVariants = cva(
    'rocco-drawer-header flex flex-col space-y-2',
    {
        variants: {
            variant: {
                default:
                    'px-6 pt-13.5 md:pt-7 pb-4 md:pb-4.5 border-b border-gray-900/30',
            },
        },
        defaultVariants: {
            variant: 'default',
        },
    },
);

export const DrawerHeader = ({
    variant,
    ...props
}: OmitStylesHTMLAttributes<HTMLDivElement> &
    VariantProps<typeof drawerHeaderVariants>) => (
    <div className={drawerHeaderVariants({ variant })} {...props} />
);

DrawerHeader.displayName = 'DrawerHeader';
