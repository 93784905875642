import * as React from 'react';

import { cva, VariantProps } from 'class-variance-authority';
import { cn } from '@rocco/utils/cn';

const separatorVariants = cva('rocco-skeleton', {
    variants: {
        variant: {
            default: 'animate-pulse rounded-md bg-muted',
        },
    },
    defaultVariants: {
        variant: 'default',
    },
});

export const Skeleton = ({
    className,
    variant,
    ...props
}: React.HTMLAttributes<HTMLDivElement> &
    VariantProps<typeof separatorVariants>) => (
    <div className={cn(separatorVariants({ variant }), className)} {...props} />
);

Skeleton.displayName = 'Skeleton';
