import * as React from 'react';
import { Drawer as DrawerPrimitive } from 'vaul';

import { cva, VariantProps } from 'class-variance-authority';
import { OmitStylesComponentProps } from '../../types';

const drawerDescriptionVariants = cva('rocco-drawer-description', {
    variants: {
        variant: {
            default: 'text-sm text-muted-foreground',
        },
    },
    defaultVariants: {
        variant: 'default',
    },
});

export const DrawerDescription = React.forwardRef<
    React.ElementRef<typeof DrawerPrimitive.Description>,
    OmitStylesComponentProps<typeof DrawerPrimitive.Description> &
        VariantProps<typeof drawerDescriptionVariants>
>(({ variant, ...props }, ref) => (
    <DrawerPrimitive.Description
        ref={ref}
        className={drawerDescriptionVariants({ variant })}
        {...props}
    />
));

DrawerDescription.displayName = DrawerPrimitive.Description.displayName;
