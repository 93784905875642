import * as React from 'react';
import { Drawer as DrawerPrimitive } from 'vaul';
import { cva, VariantProps } from 'class-variance-authority';
import { X } from 'lucide-react';

import { cn } from '../../utils/cn';
import { OmitStylesComponentProps } from '../../types';
import { DrawerPortal } from './drawer-portal';
import { DrawerOverlay } from './drawer-overlay';
import { DrawerClose } from './drawer-close';

const drawerVariants = cva(
    'rocco-drawer flex fixed justify-center w-full h-auto z-modal outline-none',
    {
        variants: {
            variant: {
                default: 'inset-x-0 bottom-0 pb-0 md:pb-6',
            },
        },
        defaultVariants: {
            variant: 'default',
        },
    },
);

const drawerContentVariants = cva(
    'rocco-drawer-content w-fit z-modal gap-4 backdrop-blur bg-gray-100/85 will-change-transform shadow-lg',
    {
        variants: {
            variant: {
                default: 'rounded-t-2xl md:rounded-2xl',
            },
        },
        defaultVariants: {
            variant: 'default',
        },
    },
);

interface DrawerContentProps
    extends OmitStylesComponentProps<typeof DrawerPrimitive.Content>,
        VariantProps<typeof drawerContentVariants> {
    hideDefaultCloseButton?: boolean;
    noOverlay?: boolean;
    noHandle?: boolean;
    limitMaxHeight?: boolean;
}

export const DrawerContent = React.forwardRef<
    React.ElementRef<typeof DrawerPrimitive.Content>,
    DrawerContentProps
>(
    (
        {
            children,
            variant,
            hideDefaultCloseButton = false,
            noOverlay = false,
            noHandle = false,
            limitMaxHeight = false,
            ...props
        },
        ref,
    ) => (
        <DrawerPortal>
            {!noOverlay && <DrawerOverlay />}
            <DrawerPrimitive.Content
                ref={ref}
                className={cn(
                    drawerVariants({ variant }),
                    // Keep max height in sync with mobile header height.
                    // packages/rocco/src/ui/website-header/components/MobileHeader.tsx
                    limitMaxHeight && 'max-h-[calc(100dvh-4.75rem)]',
                )}
                {...props}
            >
                <div
                    className={drawerContentVariants({ variant })}
                    onClick={e => e.stopPropagation()}
                >
                    {!noHandle && (
                        <div className="absolute inset-x-0 top-3 h-1 flex justify-center">
                            <div className="h-1 w-9.5 rounded-full bg-gray-600/30" />
                        </div>
                    )}
                    {children}
                    {!hideDefaultCloseButton && (
                        <DrawerClose className="absolute right-5 md:right-6 top-6 md:top-7 rounded-sm opacity-70 ring-offset-background transition-opacity hover:opacity-100 focus:outline-none focus:ring-2 focus:ring-ring focus:ring-offset-2 disabled:pointer-events-none data-[state=open]:bg-secondary">
                            <X className="h-6 w-6" />
                            <span className="sr-only">Close</span>
                        </DrawerClose>
                    )}
                </div>
            </DrawerPrimitive.Content>
        </DrawerPortal>
    ),
);

DrawerContent.displayName = 'DrawerContent';
